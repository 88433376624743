<ng-container *transloco="let t">
    <ng-container *ngIf="mode === 'input'">
        <bazis-input-default
            [formControl]="dateValue"
            [tooltipKey]="tooltipKey"
            [tooltipParams]="tooltipParams"
            [tooltipSettings]="tooltipSettings"
            [titleKey]="titleKey"
            [titleParams]="titleParams"
            [required$]="required$"
            [noteKey]="noteKey"
            [noteParams]="noteParams"
            [placeholderKey]="placeholderKey"
            [placeholder]="placeholder"
            [validationErrorMessages]="validationErrorMessages"
            [hasEraser]="hasEraser"
            [maskSettings]="maskSettings"
        >
            <ng-content></ng-content>
            <bazis-form-error
                *ngIf="dateValue.status !== 'INVALID'"
                [validationErrorMessages]="validationErrorMessages"
                [formControl]="ngControl.control"
            ></bazis-form-error>
        </bazis-input-default>
    </ng-container>
    <ng-container *ngIf="mode === 'picker'">
        <div
            *ngIf="{
            required: required$ | async,
        } as data"
            #inputDate
            clickOutside
            (clickOutside)="clickPopperOutside()"
            [excludeElement]="calendar"
        >
            <div class="bazis-control">
                <label
                    *ngIf="titleKey"
                    class="bazis-control__label"
                >
                    <bazis-interrogation
                        *ngIf="tooltipSettings?.targetSide === 'labelStart' && tooltipKey"
                        iconSize="xs"
                        [tooltipText]="t(tooltipKey, tooltipParams)"
                        [tooltipOffset]="[-8, 4]"
                        [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
                        [tooltipPlacement]="tooltipSettings?.placement || 'top-start'"
                    ></bazis-interrogation>
                    {{ t(titleKey) }}
                    <span
                        color="danger"
                        *ngIf="data.required"
                    >
                        *
                    </span>
                    <bazis-interrogation
                        *ngIf="tooltipSettings?.targetSide === 'labelEnd' && tooltipKey"
                        iconSize="xs"
                        [tooltipText]="t(tooltipKey, tooltipParams)"
                        [tooltipOffset]="[-8, 4]"
                        [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
                        [tooltipPlacement]="tooltipSettings?.placement || 'top-start'"
                    ></bazis-interrogation>
                </label>

                <div
                    class="bazis-control__field-group bh-pointer"
                    [class.bazis-control__field-group--disabled]="
                        ngControl.control.disabled || disabled
                    "
                    (click)="openCalendar($event)"
                >
                    <div
                        *ngIf="placeIcon === 'left'"
                        class="bazis-control__field-group__start"
                    >
                        <bazis-icon name="calendar"></bazis-icon>
                    </div>
                    <div class="bazis-control__field-wrapper">
                        <div class="bazis-control__field">
                            {{
                                dateValue.value
                                    ? dateValue.value
                                    : placeholder
                                    ? placeholder
                                    : placeholderKey
                                    ? t(placeholderKey)
                                    : ''
                            }}
                        </div>
                    </div>
                    <div
                        *ngIf="(ngControl.control.value && hasEraser) || placeIcon === 'right'"
                        class="bazis-control__field-group__end"
                    >
                        <bazis-button
                            *ngIf="ngControl.control.value && hasEraser"
                            fill="clear"
                            (click)="onClear()"
                            class="bazis-eraser"
                        >
                            <bazis-icon
                                slot="icon-only"
                                name="cross-small"
                            ></bazis-icon>
                        </bazis-button>
                        <bazis-button
                            *ngIf="placeIcon === 'right'"
                            fill="clear"
                        >
                            <bazis-icon
                                name="calendar"
                                slot="icon-only"
                            ></bazis-icon>
                        </bazis-button>
                    </div>
                </div>

                <div class="bazis-control__error">
                    <bazis-form-error
                        [validationErrorMessages]="validationErrorMessages"
                        [formControl]="ngControl.control"
                    ></bazis-form-error>

                    <!-- для ошибок родительского контрола
                (пример использоавния в input-text-array, предполагается сюда "вложить" bazis-form-error) -->
                    <ng-content></ng-content>
                </div>
                <div
                    *ngIf="noteKey"
                    class="bazis-control__note"
                >
                    {{ t(noteKey, noteParams) }}
                </div>
            </div>

            <div
                #calendar
                class="bh-popper-z-index"
                [hidden]="!showCalendar"
                (click)="clickPopper()"
            >
                <bazis-datepicker
                    *ngIf="showCalendar"
                    [value]="calendarValue"
                    (valueChange)="setValueFromCalendar($event)"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    [formatOutput]="displayFormat"
                    [startYear]="startYear"
                    [disabledIntervals]="disabledIntervals"
                    [marks$]="marks$"
                    [typeHeadCalendar]="typeHeadCalendar"
                    [typeSelectMonth]="typeSelectMonth"
                    [visibleAllYears]="visibleAllYears"
                ></bazis-datepicker>
            </div>
        </div>
    </ng-container>
</ng-container>
